import React from 'react'
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { Button, UnstyledLink } from '@global';

// import headshot from '../../static/assets/images/headshot.jpg';

export default function Home(props) {
  const aboutInfo = props.data.allContentfulModule.edges[0].node;
  return (
    <PageWrapper>
      <ContentWrapper>
        <GreetingLine><UnstyledLink to='/' color='#FFF'>I'm Luke, a writer.</UnstyledLink></GreetingLine>
        <div style={{ fontSize: '20px', marginBottom: '30px' }}>When I’m not reading and writing fiction, I research and report on interesting topics and help brands tell their stories.</div>
        <Button to='/contact'>Contact</Button>
      </ContentWrapper>
    </PageWrapper>
  );
}

export const homePageQuery = graphql`
  query HomeQuery {
    allContentfulModule(filter: {title: {eq: "Home Page - About"}}) {
    edges {
      node {
        title
        mainText {
          json
        }
        showButton
        buttonText
      }
    }
  }
  }
`

const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1080px;
  display: flex;
  // border: 1px solid red;
  @media (max-width: 1280px) {
    justify-content: center;
  }
  @media (max-width: 991.98px) {
    padding-top: 30px;
  }
`
const ContentWrapper = styled.div`
  padding: 160px 40px;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  // border: 1px solid green;
  @media (max-width: 480px) {
    padding: 160px 20px;
  }
`

const GreetingLine = styled.div`
  margin-bottom: 11px;
  font-size: 48.83px;
  font-weight: 700;
  @media (max-width: 480px) {
    font-size: 39.06px;
  }
  @media (max-width: 360px) {
    font-size: 31.25px;
  }
`