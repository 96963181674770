import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

/* 
-- BUTTON --

Hardcode min-width, height, border-radius, hover styling, and other cosmetic styles.
To use, import it and pass it a variant (ex. "primary" or "secondary"), text in the form of children. and "to" for link.

NOTE: notice how we spread additional styles by spreading "...props" on the <button> itself, not the wrapper.

*/

export const Button = ({ variant, to, children, ...props }) => {
  return (
      <ButtonLinkWrapper to={to} variant={variant}>
        <button
          {...props}
          data-comp={Button.displayName}
        >
          {children}
        </button>
      </ButtonLinkWrapper>
  );
};

Button.displayName = 'Button';

const ButtonLinkWrapper = styled(Link)`
  text-decoration: none;

  button {
    padding: 12px 30px;
    height: 50px;
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    ${({ variant }) => variant === 'secondary' && `
      background: #00D1FF;
    `}
    ${({ variant }) => variant === 'tertiary' && `
      background: #222222;
    `}
    border-radius: 0px;
    border: 1px solid #08FDD8;
    outline: none;
    color: #08FDD8;
    font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    line-height: 19px;
    transition: background-color 200ms ease, color 200ms ease-out;
    will-change: transform;

    &:hover {
      background-color: #08FDD8;
      border: 1px solid transparent;
      color: #121212;
      ${({ variant }) => variant === 'secondary' && `
      border: 1px solid #00D1FF;
      color: #00D1FF;
      `}
      ${({ variant }) => variant === 'tertiary' && `
      border: 1px solid #4895EF;
      color: #4895EF;
      `}
    }
  }
`